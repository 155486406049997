.wrapper_error {
  width: 100vw;
  background-color: #e2e5f0;
  height: 100vh;
  padding: 80px 40px;
}
.error_page {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.error_page_wrapper {
  width: 45%;
  max-width: 480px;
}
.error_icon {
  display: flex;
  justify-content: center;
}
.error_icon img {
  height: 100%;
  width: auto;
}
.error_code {
  text-align: center;
  font-weight: 800;
  font-size: 70px;
  color: #1890ff;
  margin-bottom: 8px;
}
.error_text {
  text-align: center;
  font-weight: 600;
  font-size: 26px;
  color: #2c2e35;
  margin-bottom: 24px;
}
.error_button {
  font-size: 1.2em;
  text-align: center;
  border-radius: 4px;
  background: #1890ff;
  border: 1px solid #1890ff;
  color: white;
  width: 100%;
  padding: 8px 16px;
}

@media (max-width: 1024px) {
  .wrapper_error {
    width: 100vw;
    background-color: #e2e5f0;
    height: 100vh;
    padding: 80px 40px;
  }
  .error_page {
    background-color: #ffffff;
    border-radius: 20px;
    width: 100%;
    padding: 60px 248px;
  }
  .error_page_wrapper {
    width: 100%;
    height: 100%;
  }
  .error_icon img {
    width: 300px;
    height: auto;
  }
  .error_code {
    font-size: 60px;
  }
  .error_text {
    font-size: 20px;
  }
  .error_button {
    height: 36px;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .error_page {
    padding: 24px 16px;
  }
  .error_icon img {
    width: 180px;
    height: auto;
  }
  .error_code {
    font-size: 50px;
  }
  .error_text {
    font-size: 16px;
  }
}
