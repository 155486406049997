.wrapper {
  background-color: #ffffff;
  height: 100vh;
  width: 100vw;
  position: fixed;
  pointer-events: none;
  z-index: 10000001;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}
.content {
  width: 100px;
  height: 100px;
  margin-top: 300px;
  position: relative;
}
.animated {
  height: 100%;
  animation: rotation 2s infinite linear;
}
.animated img {
  width: 100%;
  height: 100%;
}

@keyframes rotation {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
