.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e2e5f0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #1890ff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}
.slider.round {
  border-radius: 32px;
}

.slider.round:before {
  border-radius: 50%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap : 12px;
}
.email {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  gap: 8px;
  text-decoration: none;
  color: #1890ff;
  margin-bottom: 10px;
}
