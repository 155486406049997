.navBar {
  display: flex;
  height: 100vh;
  flex-direction: column;
  background: #ffffff;
  width: 240px;
  min-width: 240px;
}
.logo {
  margin-top: 13%;
  margin-bottom: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoImg {
  width: 9vw;
  height: auto;
}
.button,
.activeButton {
  padding: 1.8vh 1.5vw;
  flex: 1;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  color: black;
}

.button:hover,
.activeButton:hover{
  cursor: pointer;
  opacity: 0.5;
  background-color: #e9f6fe;
}

.activeButton {
  background-color: #e9f6fe;
  color: #1890ff;
  border-left: 3px solid #1890ff;
}

.button img,
.activeButton img {
  width: 0.8rem;
  height: auto;
  margin-top: 3px;
  margin-right: 0.6vw;
}
@media (max-width: 1024px) {
  .navBar {
    padding-top: 64px;
    height: 100%;
    width: 100% !important;
    margin-left: 500px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  .button {
    padding-left: 24px !important;
  }
  .logo {
    position: absolute;
    display: none;
  }
  .button img {
    margin-right: 7px;
  }
  .logoImg {
    display: none;
  }
}
@media (max-width: 768px) {
  .navBar {
    padding-top: 20px;
    height: 100%;
    width: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    margin: 0;
    margin-top: 44px;
  }
  .button,
  .activeButton {
    padding-left: 24px !important;
  }
  .logo {
    position: absolute;
    display: none;
  }
  .button img {
    margin-right: 7px;
  }
  .logoImg {
    display: none;
  }
}
