.container {
  display: flex;
  background-color: #e2e5f0;
  min-height: 100vh;
}
.wrapper {
  background: #ffffff;
  border-radius: 20px;
  margin: 32px 40px;
  padding: 1.8% 2%;
  height: 100%;
  position: relative;
}
.container_logo {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }
  .wrapper {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    align-items: center;
  }
  .cards,
  .applications,
  .overflow {
    width: 608px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    margin: 0;
    padding: 64px 16px;
    border-radius: 0;
  }
  .cards,
  .applications,
  .overflow {
    width: 100%;
  }
}
