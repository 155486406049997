.warning:hover {
  cursor: pointer;
}

.error:hover {
  cursor: pointer;
}

.warning,
.error {
  display: flex;
  margin-bottom: 8px;
}

.warning div {
  font-size: 14px;
  font-weight: 400;
  color: #ffa216;
}

.error div {
  font-size: 14px;
  font-weight: 400;
  color: #fe5b37;
}
.warning img {
  margin-right: 6px;
  height: 20px;
}

.error img {
  margin-right: 6px;
  height: 20px;
}
