.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  color: #2c2e35;
}
.item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.buttonContainer {
  display: flex;
  width: 100%;
  gap: 16px;
}
.buttonContainer button {
  width: 50%;
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  transition: 0.5s;
  border-radius: 4px;
}
.buttonContainer button:hover {
  cursor: pointer;
  opacity: 0.5;
}
.buttonContainer button img {
  width: 16px;
  height: 16px;
}
.save {
  background-color: #4db84b;
  border: 1px solid #4db84b;
}
.delete {
  background-color: #fe5b37;
  border: 1px solid #fe5b37;
}
