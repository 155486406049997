.wrapper {
  display: flex;
  gap : 24px;
  margin-bottom: 32px;
}
.block {
  width: calc(100% / 4);
}
.block:not(:last-child) {
  border-right: 1px solid #dbe0f2;
}
.value {
  margin-left: 8px;
  color: #2c2e35;
}

.item {
  margin-top: 12px;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 16px;
  color: #696e7e;
}

.title {
  font-weight: 500;
  font-size: 18px;
  color: #2c2e35;
}

@media (max-width: 767px) {
  .wrapper {
    display: block;
  }
  .block {
    border-right: none !important;
    margin-left: 0 !important;
    margin-bottom: 8px;
  }
  .block:not(:last-child) {
    border-bottom: 1px solid #dbe0f2;
  }
}
