.title {
  margin-bottom: 4px;
  display: flex;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #2c2e35;
}
.selected {
  color: #2c2e35;
}
.placeholder {
  color: #8e95b0;
}
.selectorHead {
  padding: 10px 12px;
  border: 2px solid #dbe0f2;
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.selectorHead.error {
  border: 1px solid #fe5b37;
}

.selectorHead img {
  width: 14px;
  height: 14px;
}
.selectorWrapper {
  position: relative;
  width: 100%;
  z-index: 100;
}
.selector {
  border: 2px solid #dbe0f2;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  overflow-y: auto;
  max-height: 220px;
}

.selector::-webkit-scrollbar {
  display: none;
}

.option {
  color: #2c2e35;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.option:not(:last-child) {
  border-bottom: 2px solid #dbe0f2;
}

.option label input {
  position: absolute;
  display: none;
}

.option label {
  padding: 12px;
  width: 100%;
  display: flex;
}

.option label:hover {
  cursor: pointer;
  background-color: #dbe0f2;
}
