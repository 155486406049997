.status {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.status span {
  padding: 2px 8px;
  border-radius: 6px;
}
.offline {
  color: #fe5b37;
  background-color: #ffdcd4;
}
.online {
  background-color: #d4fbd4;
  color: #4db84b;
}
