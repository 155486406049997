.deviceInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.deviceInfo p {
  color: #2c2e35;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.deviceInfo span {
  color: #8e95b0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
