.button {
  color: #1890ff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  transition: 0.5s;
}
.button:hover {
  opacity: 0.5;
  cursor: pointer;
}
.notOk {
  color: #8e95b0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.notOk span {
  color: #ffa216;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.ok {
  color: #2c2e35;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.ok span {
  color: #4db84b;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.declined {
  color: #2c2e35;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.declined span {
  color: #fe5b37;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
