.background {
  background-color: #e2e5f0;
  padding: 40px 80px;
  height: 100vh;
}
.container {
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  height: 100%;
  padding-top: 100px;
}

@media (max-width: 1024px) {
  .background {
    padding: 40px;
  }
  .container {
    height: fit-content;
    padding: 120px 0px;
  }
}

@media (max-width: 768px) {
  .background {
    padding: 16px;
  }
  .container {
    padding: 24px 16px;
  }
}
