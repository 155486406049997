.component:not(:last-child) {
  border-bottom: 2px solid #dbe0f2;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.component {
  display: flex;
  justify-content: space-between;
}
.floorBlock {
  color: #2c2e35;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.title {
  font-weight: 500;
}
.name {
  display: flex;
  gap: 8px;
}
.info {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.model {
  font-weight: 500;
}
.date {
  color: #8e95b0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

@media (max-width: 768px) {
  .component {
    flex-direction: column;
    justify-content: left;
    gap: 8px;
  }
  .floorBlock {
    text-align: left !important;
  }
}
