.wrapper {
  position: relative;
}
.head {
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.head.select_active:hover {
  cursor: pointer;
  background-color: #dbe0f2;
}
.head img {
  width: 14px !important;
  height: 14px !important;
}
.head.none {
  border: none;
}
.wrapper ul {
  z-index: 1;
  position: absolute;
  list-style-type: none;
  background-color: #eff1f9;
  border: 1px solid #dbe0f2;
  width: 100%;
  padding: 0;
}

.wrapper ul li {
  padding: 12px;
}
.wrapper ul li:hover {
  cursor: pointer;
  background-color: #dbe0f2;
}
.wrapper ul li:not(:last-child) {
  border-bottom: 1px solid #dbe0f2;
}
@media (max-width: 1024px) {
  .head {
    padding: 10px 12px;
    border: 1px solid #dbe0f2;
  }
  .head.none {
    padding: 0;
  }
  .wrapper ul {
    width: 100%;
    margin-top: 6px;
  }
}
