.tokenStatus {
  color: #4db84b;
  font-size: 14px;
  font-weight: 500;
}
.tokenContainer {
  margin-top: 24px;
  border: 1px solid #dbe0f2;
  border-radius: 8px;
  padding: 8px;
}
.tokenContainer div {
  display: flex;
  gap: 4px;
  margin-bottom: 16px;
}
.textToken {
  word-break: break-all;
  width: 100%;
}

.copy {
  height: 20px;
  width: 20px;
  opacity: 1;
}
.copy:hover {
  opacity: 0.5;
  cursor: pointer;
}

.token {
  width: 100%;
}
