.table {
  table-layout: auto;
  word-break: break-all;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #dbe0f2;
  border-radius: 8px;
  width: 100%;
  min-width: 1000px;
  box-sizing: content-box;
  white-space: nowrap;
}

.table th,
.table td {
  border-right: 1px solid #dbe0f2;
  border-bottom: 1px solid #dbe0f2;
  padding: 8px;
}

.table th:last-child,
.table td:last-child {
  border-right: none;
}

.table tr:last-child td {
  border-bottom: none;
}

.table tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

.table tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

.table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}
.table th {
  background: #eff1f9;
  font-size: 14px;
  font-weight: 500;
}
.td_table {
  word-break: normal;
}
.table td,
th {
  border: 1px solid #dbe0f2;
  padding: 0.8vh 0.9vw;
  text-align: center;
}
.table td {
  font-size: 14px;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}
.empty_table {
  text-align: center !important;
  height: 15vh;
}
.empty_table img {
  margin: 46px;
  max-width: 100% !important;
  max-height: 100% !important;
  height: auto !important;
  width: auto !important;
}
.table td img {
  display: inline-flex;
  width: 1.5vw;
  height: auto;
  max-width: 20px;
}
