.settingsWrapper h1 {
  font-weight: 500;
  font-size: 20px;
  color: #2c2e35;
  margin-bottom: 21px;
}
.settingsInfo {
  display: flex;
  gap: 8px;
  font-size: 16px;
  margin-bottom: 16px;
}
.settingsInfo p {
  font-weight: 400;
  color: #696e7e;
}
.settingsInfo b {
  font-weight: 500;
  color: #2c2e35;
}
