.table td .change_value_input {
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  border: 1px solid #1890ff;
  padding: 4px 6px;
  text-align: left;
  background-color: transparent;
  box-shadow: none;
}
