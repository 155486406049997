.cardsContainer {
  display: flex;
  background-color: #e2e5f0;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
}

.cardsWrapper {
  background: #ffffff;
  border-radius: 20px;
  margin: 32px 40px;
  padding: 1.8% 2%;
  height: 100%;
  width: calc(100% - 300px);
  position: relative;
  box-sizing: border-box;
}

@media (max-width: 1024px) {
  .cardsContainer {
    flex-direction: column;
  }
  .cardsWrapper {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    align-items: center;
    width: 90%;
  }
  .cards,
  .applications,
  .overflow {
    width: 100%;
    max-width: 608px;
  }
}

@media (max-width: 767px) {
  .cardsWrapper {
    margin: 0;
    padding: 64px 16px;
    border-radius: 0;
    width: 100%;
  }
  .cards,
  .applications,
  .overflow {
    width: 100%;
  }
}